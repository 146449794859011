import React, {useEffect, useState} from "react";
import {
    getInternalCustomer,
    getInternalPageData,
    getProductData,
    modifyUserCartInternal,
    savePayOrder
} from "../../services/api";
import {Link, useNavigate, useParams} from "react-router-dom";
import _ from "lodash";
import {Alert, Avatar, Button, Col, DatePicker, Divider, Form, Image, Input, message, Row, Table, Tag} from "antd";
import {Popup, Loading} from "antd-mobile";
import {
    CheckCircleFilled, CloseCircleFilled,
    DollarCircleFilled, PercentageOutlined,
    PhoneOutlined,
    ShoppingCartOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import {PRIMARY_COLOR, RUPEE} from "../../models/constants";
import moment from "moment";
import {convertArrayToMap, requiredRule} from "../../utils";
import {getCart, saveCart} from "../../services/cookies";
import logoTi from "../../res/img/logo_ti.png";
import TiModalRenew from "./renewModal";
import {SwitchSelect} from "../../components/switch";
import { ButtonV2 } from "../../components/ButtonV2";
import {CheckIcon} from "antd-mobile/es/components/checkbox/check-icon";

const actualSecretKey = "VLLLLL";

const isKycApproved = (device) => {
    return device?.kycLink?.status === "approved";
}

const TiSelfcareHome = () => {

    const params = useParams();
    const navigate = useNavigate();
    const {customerId} = params;
    const userHash = customerId;
    const isProductVerificationFlow = customerId?.startsWith?.("v-");
    const [productData, setProductData] = useState({initVal: true});
    const [customer, setCustomer] = useState({});
    const [pageData, setPageData] = useState({});
    const [cart, setCart] = useState([]);
    const [showCart, setShowCart] = useState(false);
    const [showRenewModal, setShowRenewModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState();
    const [creatingOrder, setCreatingOrder] = useState(false);
    const [secretKey, setSecretKey] = useState("");
    const [form] = Form.useForm();
    const plans = pageData?.plans || [];
    const plansMap = _.keyBy(plans, "planId");

    const isAdmin = !!pageData?.isAdmin; // secretKey === actualSecretKey;

    const handleSecretKey = (_char) => {
        if (isAdmin) {
            return;
        }
        const newSecretKey = `${secretKey}${_char}`.slice(-6);
        setSecretKey(newSecretKey);
    }

    console.log("## Cart ##", cart);
    let cartTotal = 0;
    cart.forEach((cartItem) => {
        cartTotal += plansMap[cartItem.planId]?.price || 0;
    });

    const checkIfExistsInCart = (deviceId) => {
        for (const cartItem of cart) {
            if (cartItem.deviceId === deviceId) {
                return true;
            }
        }
        return false;
    }

    const addItemToCart = async (plan) => {
        // const clonedCart = _.cloneDeep(cart);
        // clonedCart.push({
        //     deviceId: selectedSubscription.DeviceID,
        //     plan
        // });
        // setCart(clonedCart);
        // await new Promise(resolve => setTimeout(resolve, 2000));
        const response = await modifyUserCartInternal({
            userHash,
            action: "add",
            params: {
                deviceId: selectedSubscription.DeviceID,
                planId: plan.planId,
            }
        });
        setCart(response.data.data.cart?.items || []);
    }

    const removeItemFromCart = async (deviceId) => {
        // const clonedCart = _.cloneDeep(cart).filter(cartItem => cartItem.deviceId !== deviceId);
        // setCart(clonedCart);
        // saveCart(clonedCart);
        // await new Promise(resolve => setTimeout(resolve, 2000));
        const response = await modifyUserCartInternal({
            userHash,
            action: "remove",
            params: {
                deviceId,
            }
        });
        setCart(response.data.data.cart?.items || []);
    }

    // const [products, setProducts] = useState([]);

    // const fetchProducts = async () => {
    //     await getInternalProducts().then(resp => setProducts(resp.data.data.products));
    //     console.log("## Fetched Products ##");
    // }


    useEffect(() => {
        document.title = "TrakInfinity Selfcare";
    }, [])


    useEffect(() => {
        const fetchCustomer = async () => {
            const response = await getInternalPageData(customerId);
            if (!response.data.success) {
                message.error("Invalid Customer");
                setPageData({
                    customerDetails: {
                        name: "Invalid Customer",
                    }
                });
                return;
            }
            setPageData(response.data.data);
            setCart(response.data.data.cart?.items || []);
        }

        const fetchProductData = async () => {
            const response = await getProductData(customerId);
            if (!response.data.success) {
                message.error("Invalid Product ID");
                setProductData({
                    isAuthenticated: false,
                });
                return;
            }
            setProductData({
                isAuthenticated: true,
                ...response.data.data,
            });
        }

        if (isProductVerificationFlow) {
            fetchProductData();
        } else {
            fetchCustomer();
        }

        // const _cart = getCart();
        // console.log("## Fetching Cart ##", _cart);
        // setCart(_cart);
    }, [customerId]);


    const getSubscriptionDetails = (deviceId, planId) => {
        const plan = plansMap[planId];
        const {subscriptions = []} = pageData;
        const subscriptionsMap = convertArrayToMap(subscriptions, "DeviceID");
        const subscription = subscriptionsMap[deviceId];
        const title = <div>
            {subscription?.DeviceID}
            <br/>
            ({subscription?.VehicleNumber})
        </div>;
        const newExpiry = moment(subscription?.ExpiryDate).add(plan.duration, plan.durationUnit).format("DD MMM YYYY");
        return {
            title,
            currentExpiry: moment(subscription?.ExpiryDate).format("DD MMM YYYY"),
            newExpiry,
        }
    }

    const tdStyles = {border: '1px solid black', paddingLeft: 6};
    const cTdStyles = {border: '1px solid black', padding: 6};

    const renewalColumns = [
        {
            title: "S.No.",
            render: (curr, obj, index) => (index + 1).toString()
        },
        {
            title: "Plan Name",
            dataIndex: "name",
        },
        {
            title: "Duration",
            dataIndex: "duration",
        },
    ];

    const onClickProceed = async (manualFormData) => {
        try {
            setCreatingOrder(true);
            const response = await savePayOrder({
                payOrder: {
                    type: "trakinfinity_renewal",
                    company: "61a3cd3ca1be8b3f0f7aba85",
                    pg: manualFormData ? "cash" : "razorpay",
                    orderDetails: {
                        cart,
                        customerName: pageData.customerDetails?.name,
                        customerMobile: pageData.customerDetails?.mobile,
                        userHash: userHash,
                        manualFormData,
                    }
                }
            });

            setCreatingOrder(false);
            const orderId = response.data.data.payOrder.orderId;
            console.log("## OrderID ##", orderId);
            if (manualFormData) {
                navigate(`/pay/${orderId}/summary`);
            } else {
                navigate(`/pay/${orderId}/gateway`);
            }
        } catch (err) {
            console.log("## Failed to create Order ##", err);
        } finally {
            setCreatingOrder(false);
        }
    }

    const cellStyle = {
        border: '1px solid black',
        paddingLeft: '8px',
        paddingRight: '8px',
        textAlign: 'left',
    };


    return (
        <div style={{background: '#eeeeee', height: '100%', minHeight: '100vh'}}>
            {/*<div>*/}
            {/*    {JSON.stringify(customer)}*/}
            {/*</div>*/}
            <div style={{padding: 12}}>
                <Row align={"middle"} justify={"center"} style={{border: '0px solid black'}}>
                    <Col span={24} style={{maxWidth: '18cm', border: '0px solid red'}}>
                        <Row>
                            <Col span={8}>
                                <Image
                                    onClick={() => handleSecretKey("L")}
                                    preview={false}
                                       src={logoTi}
                                       style={{maxWidth: '100%', width: 168, padding: 8}}/>
                            </Col>
                            <Col span={16} style={{paddingRight: 8, textAlign: 'right'}}>
                                {/*<h2 style={{fontWeight: 'bold'}}>*/}
                                {/*    /!*{"Infinite Telematics Pvt Ltd"}*!/*/}
                                {/*</h2>*/}
                                {/*&nbsp;&nbsp;*/}
                                <br/>
                                <a href={"tel:8010666611"}>
                                <Avatar size={40} style={{backgroundColor: PRIMARY_COLOR}} icon={<PhoneOutlined/>}/>
                                </a>
                                &nbsp;&nbsp;
                                <a href={"https://wa.me/918010666611?text=I%20need%20help%20in%20selfcare%20portal"}>
                                <Avatar size={40} style={{backgroundColor: '#87d068'}} icon={<WhatsAppOutlined/>}/>
                                </a>
                            </Col>

                        </Row>
                    </Col>
                    {
                        isProductVerificationFlow &&
                        <Col span={24} style={{maxWidth: '26cm', border: '0px solid red'}}>
                            <Divider/>
                            {productData.isAuthenticated ?
                                <Alert
                                    icon={<CheckCircleFilled/>}
                                    type={"success"}
                                    // message={"Discounted Pricing"}
                                    description={"Product is Valid"}
                                    showIcon={true}
                                /> :
                                (!productData?.initVal && <Alert
                                    icon={<CloseCircleFilled/>}
                                    type={"error"}
                                    // message={"Discounted Pricing"}
                                    description={"Failed to verify Product"}
                                    showIcon={true}
                                />)
                            }

                            <table style={{
                                width: '100%',
                                // maxWidth: '18cm',
                                marginTop: 20,
                                borderCollapse: 'collapse',
                                border: '1px solid black',
                                fontSize: 16
                            }}>
                                <tr>
                                    <td style={tdStyles}>Serial No.</td>
                                    <td style={tdStyles}>{customerId.slice(2)}</td>
                                </tr>
                                <tr>
                                    <td style={tdStyles}>Product</td>
                                    <td style={tdStyles}>{productData?.product || "---"}</td>
                                </tr>
                                <tr>
                                    <td style={tdStyles}>Brand</td>
                                    <td style={tdStyles}>{productData?.brandName || "---"}</td>
                                </tr>
                                <tr>
                                    <td style={tdStyles}>Variant</td>
                                    <td style={tdStyles}>{
                                        [productData.model, productData.variant, productData.color].filter(x => !!x).join(" / ")
                                    }</td>
                                </tr>
                            </table>
                        </Col>

                    }
                    {
                        !isProductVerificationFlow &&
                        <>
                            <Col span={24} style={{maxWidth: '26cm', border: '0px solid red'}}>
                            <Divider/>
                            <table style={{
                                width: '100%',
                                // maxWidth: '18cm',
                                borderCollapse: 'collapse',
                                border: '1px solid black',
                                fontSize: 16
                            }}>
                                <tr>
                                    <td style={{...tdStyles, textAlign: 'center', fontWeight: 'bold'}} colSpan={2}>
                                        Account Details
                                    </td>
                                </tr>
                                <tr>
                                    <td style={tdStyles}>Name</td>
                                    <td style={tdStyles}>{pageData.customerDetails?.name || "---"}</td>
                                </tr>
                                <tr>
                                    <td style={tdStyles}>Regd. Mobile</td>
                                    <td style={tdStyles}>{pageData.customerDetails?.mobile || "---"}</td>
                                </tr>
                            </table>
                            <br/>
                            {
                                pageData.isOfferApplied &&
                                <Alert
                                    icon={<PercentageOutlined />}
                                    type={"warning"}
                                    // message={"Discounted Pricing"}
                                    description={<div>
                                        Special Offer has been applied to your account for limited time only (Valid until <b>{pageData.offerExpiry}</b>)
                                        <table style={{width: '80%', border: '1px solid black', borderCollapse: 'collapse'}}>
                                            {
                                                (plans || []).map(p =>
                                                    <tr>
                                                        <td style={cellStyle}>{p.name} Recharge</td>
                                                        <td style={cellStyle}>
                                                            {
                                                                pageData.offerType === "discount" ?
                                                                    <>
                                                                        {RUPEE} <strike>{p.originalPrice}</strike> {p.price}
                                                                    </> :
                                                                    <>
                                                                    {p.extra}
                                                                    </>
                                                            }

                                                        </td>
                                                    </tr>
                                                )
                                            }

                                            {/*<tr>*/}
                                            {/*    <td style={cellStyle}>1 Year Recharge</td>*/}
                                            {/*    <td style={cellStyle}>{RUPEE} <strike>1800</strike> 1500</td>*/}
                                            {/*</tr>*/}
                                            {/*<tr>*/}
                                            {/*    <td style={cellStyle}>2 Years Recharge</td>*/}
                                            {/*    <td style={cellStyle}>{RUPEE} <strike>3200</strike> 2800</td>*/}
                                            {/*</tr>*/}
                                            {/*<tr>*/}
                                            {/*    <td style={cellStyle}>3 Years Recharge</td>*/}
                                            {/*    <td style={cellStyle}>{RUPEE} <strike>4200</strike> 3800</td>*/}
                                            {/*</tr>*/}

                                        </table>
                                        {pageData.offerType !== "discount" && <p style={{marginTop: 8}}><i>Extra Free subscription will be credited within 24 hours of recharge</i></p>}
                                    </div>}
                                    showIcon={true}
                                />
                            }
                            </Col>
                            <Col span={24}>
                            <Divider/>
                            <h2>GPS Subscriptions</h2>
                            <Row>
                                {
                                    (pageData.subscriptions || []).map(subscription => {
                                            let type;
                                            const diff = moment().diff(moment(subscription.ExpiryDate));
                                            if (diff >= 0) {
                                                type = "expired";
                                            } else if (diff > 100) {
                                                type = "expiringSoon";
                                            } else {
                                                type = "active"
                                            }

                                            const buttons = {
                                                expired: {
                                                    color: "red",
                                                    label: "Expired"
                                                },
                                                expiringSoon: {
                                                    color: "orange",
                                                    label: "Expiring Soon"
                                                },
                                                active: {
                                                    color: "green",
                                                    label: "Active"
                                                }
                                            };
                                            const button = buttons[type || "expired"];
                                            return <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                                                <div style={{
                                                    border: '2px solid lightgrey',
                                                    background: 'white',
                                                    boxSizing: 'border-box',
                                                    padding: 8,
                                                    marginBottom: 8,
                                                }}>
                                                    <Row>
                                                        <div style={{width: '100%'}}>
                                                            <div style={{float: 'left', fontWeight: 'bold', fontSize: 18}}>
                                                                {subscription.VehicleNumber}
                                                            </div>
                                                            <div style={{float: 'right', marginRight: 16}}>
                                                        <span style={{
                                                            background: button.color, color: 'white',
                                                            fontWeight: 'bold', paddingLeft: 8, paddingRight: 8
                                                        }}>
                                                            {button.label}
                                                        </span>
                                                            </div>

                                                        </div>
                                                    </Row>
                                                    <div>
                                                        <table>
                                                            <tr>
                                                                <td>Device ID</td>
                                                                <td style={{paddingLeft: 16}}>
                                                                    {subscription.DeviceID || "---"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>KYC Status</td>
                                                                <td style={{paddingLeft: 16}}>
                                                                    {
                                                                        isKycApproved(subscription) ?
                                                                            <Tag color={"green"}>Completed</Tag> :
                                                                            <div>
                                                                                <Tag color={"red"}>
                                                                                    {subscription?.kycLink?.status === "inReview" ? "In Review" : "Pending"}
                                                                                </Tag>
                                                                                &nbsp;&nbsp;
                                                                                <Link
                                                                                    to={`/selfcare/kyc/${userHash}/${subscription.DeviceID}`}>
                                                                                    <u>
                                                                                        Complete KYC Now
                                                                                    </u>
                                                                                </Link>
                                                                            </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Vehicle Model</td>
                                                                <td style={{paddingLeft: 16}}>
                                                                    {subscription.VehicleModel || "---"}
                                                                </td>
                                                            </tr>
                                                            {/*<tr>*/}
                                                            {/*    <td>Activation Date:</td>*/}
                                                            {/*    <td style={{paddingLeft: 16}}>*/}
                                                            {/*        {moment(subscription.activationDate).format("DD MMM YYYY")}*/}
                                                            {/*    </td>*/}
                                                            {/*</tr>*/}
                                                            <tr>
                                                                <td>Expiry Date</td>
                                                                <td style={{paddingLeft: 16}}>
                                                                    {moment(subscription.ExpiryDate).format("DD MMM YYYY")}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div style={{padding: 0}}>
                                                        {
                                                            checkIfExistsInCart(subscription.DeviceID) ?
                                                                <ButtonV2 type={"danger"}
                                                                          style={{
                                                                              width: !isKycApproved(subscription) ? '48%' : '100%',
                                                                              borderRadius: 0,
                                                                              fontWeight: 'bold'
                                                                          }}
                                                                          onClick={async () => {
                                                                              await removeItemFromCart(subscription.DeviceID);
                                                                          }}
                                                                >
                                                                    Remove from Cart
                                                                </ButtonV2> :
                                                                <Button type={"primary"}
                                                                        style={{
                                                                            width: !isKycApproved(subscription) ? '48%' : '100%',
                                                                            borderRadius: 0,
                                                                            fontWeight: 'bold'
                                                                        }}
                                                                        onClick={() => {
                                                                            setSelectedSubscription(subscription);
                                                                            setShowRenewModal(true);
                                                                        }}
                                                                >
                                                                    Renew Subscription
                                                                </Button>
                                                        }
                                                        {
                                                            !isKycApproved(subscription) &&
                                                            <Button
                                                                type={"primary"}
                                                                style={{
                                                                    width: '48%',
                                                                    marginLeft: '4%',
                                                                    borderRadius: 0,
                                                                    fontWeight: 'bold'
                                                                }}
                                                                onClick={() => {
                                                                    navigate(`/selfcare/kyc/${userHash}/${subscription.DeviceID}`);
                                                                }}
                                                            >
                                                                Start KYC
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        }
                                    )
                                }
                            </Row>
                            <Divider/>
                            {/*<h2>Renewals</h2>*/}
                            {/*<Table*/}
                            {/*    dataSource={customer?.renewals || []}*/}
                            {/*    columns={renewalColumns}*/}
                            {/*/>*/}
                        </Col>
                    </>}
                </Row>
            </div>
            {
                !!cart.length &&
                <div style={{position: 'fixed', bottom: 12, width: '100%', textAlign: 'center', paddingLeft: 8, paddingRight: 8}}>
                    <center>
                        <div onClick={() => {
                            handleSecretKey("V");
                            setShowCart(true)
                        }} style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            maxWidth: '10cm',
                            width: '100%',
                            background: PRIMARY_COLOR,
                            color: 'white',
                            padding: 8,
                            paddingTop: 16,
                            paddingBottom: 16,
                            borderRadius: 200
                        }}>
                            {/*<Badge size="default" count={5}>*/}
                            {/*    <Avatar icon={<ShoppingCartOutlined/>} size="large" style={{backgroundColor: PRIMARY_COLOR}} />*/}
                            {/*</Badge>*/}
                            <Row>
                                <Col span={12} style={{borderRight: '1px solid white'}}>
                                    {cart.length} items &nbsp;&nbsp;&#9679;&nbsp;&nbsp; ₹ {cartTotal}
                                </Col>
                                <Col span={12}>
                                    <ShoppingCartOutlined/>&nbsp;&nbsp;
                                    View Cart
                                </Col>
                            </Row>
                            {/*<ShoppingCartOutlined/> &nbsp;&nbsp;&nbsp;*/}
                            {/*View Cart (₹ 700)*/}
                        </div>
                    </center>
                </div>
            }
            <TiModalRenew
                plans={pageData.plans || []}
                visible={showRenewModal}
                subscription={selectedSubscription}
                setShowRenewModal={setShowRenewModal}
                addItemToCart={addItemToCart}
            />

            <Popup
                visible={showCart}
                onMaskClick={() => setShowCart(false)}
            >
                <h1 style={{padding: 16, fontWeight: 'bold'}}>Cart</h1>
                <div style={{textAlign: 'center', width: '100%', border: '0px solid blue'}}>
                    <table style={{width: '90%', marginLeft: '5%', marginRight: '5%'}}>
                        {
                            <>
                                <tr>
                                    <th style={cTdStyles}>S.No.</th>
                                    <th style={cTdStyles}>Device ID</th>
                                    <th style={cTdStyles}>Renewal Plan</th>
                                    <th style={cTdStyles}>Amount</th>
                                </tr>
                                {
                                    cart.map((item, index) => {
                                        const details = getSubscriptionDetails(item.deviceId, item.planId);
                                        const plan = plansMap[item.planId];
                                        return <tr>
                                            <td style={cTdStyles}>{index + 1}</td>
                                            <td style={cTdStyles}>{details.title}</td>
                                            <td style={cTdStyles}>{plan.name}</td>
                                            <td style={cTdStyles}>{plan.price}</td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <td style={{
                                        ...cTdStyles,
                                        textAlign: 'right',
                                        color: 'green',
                                        fontWeight: 'bold',
                                        border: 'none',
                                        fontSize: 16
                                    }} colSpan={6}>
                                        Total Amount: ₹{cartTotal}
                                    </td>
                                </tr>
                            </>
                        }
                    </table>
                    {/*<div style={{width: '100%', textAlign: 'right', color: 'green', fontWeight: 'bold', fontSize: 18}}>*/}
                    {/*    Total Amount: ₹{cartTotal}*/}
                    {/*</div>*/}
                    <Button disabled={creatingOrder} type={"primary"}
                            style={{marginBottom: 32, fontWeight: 'bold', fontSize: 16}} onClick={() => onClickProceed()}>
                        {
                            creatingOrder ? <Loading/> : "Proceed to Pay"
                        }
                    </Button>
                    {
                        isAdmin &&
                            <>
                                <br/>
                                <Form
                                    layout={"vertical"}
                                    style={{border: '1px solid black', margin: 8, padding: 8}}
                                    form={form}
                                    initialValues={{
                                        rechargeDate: moment(),
                                    }}
                                    onFinish={async (formData) => {
                                        console.log("## FormData ##", formData);
                                        formData.isPaymentReceived = !!formData.isPaymentReceived;
                                        formData.sendSms = !!formData.sendSms;
                                        formData.remarks = formData.remarks || "";
                                        if (formData.remarks.length < 3) {
                                            message.error("Invalid remarks");
                                            return;
                                        }

                                        await onClickProceed(formData);
                                    }}
                                >
                                    <Form.Item label={"Date"} name={"rechargeDate"} >
                                        <DatePicker format={"DD MMM YYYY"} />
                                    </Form.Item>
                                    <Form.Item name={"sendSms"}
                                        // label={"Payment Received ?"}
                                    >
                                        <SwitchSelect
                                            checkedChildren={"Send SMS"}
                                            unCheckedChildren={"Dont send SMS"}
                                            valuesMap={{
                                                true: true,
                                                false: false,
                                            }}
                                            reverseMap={{
                                                true: true,
                                                false: false
                                            }}
                                            // size={"small"}
                                        />
                                    </Form.Item>
                                    <Form.Item name={"isPaymentReceived"}
                                               // label={"Payment Received ?"}
                                    >
                                        <SwitchSelect
                                            checkedChildren={"Payment Received"}
                                            unCheckedChildren={"Payment Pending"}
                                            valuesMap={{
                                                true: true,
                                                false: false,
                                            }}
                                            reverseMap={{
                                                true: true,
                                                false: false
                                            }}
                                            // size={"small"}
                                        />
                                    </Form.Item>
                                    {/*<br/><br/>*/}
                                    <Form.Item name={"remarks"}
                                               style={{width: '100%'}}
                                               // label={"Remarks"}
                                    >
                                        <Input style={{width: '100%'}} placeholder={"Remarks"}/>
                                    </Form.Item>

                                    {/*<br/>*/}
                                    <Button htmlType={"submit"} disabled={creatingOrder} type={"primary"}
                                            style={{marginBottom: 32, fontWeight: 'bold', fontSize: 16}}
                                    >
                                        {
                                            creatingOrder ? <Loading/> : "Manual Order"
                                        }
                                    </Button>


                                </Form>

                            </>
                    }
                </div>
            </Popup>

        </div>
    )
};

export default TiSelfcareHome;
